import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components';
import './header.css'
import hplogo from '../../images/healthpeer-white.png'
import appStore from '../../images/App Store.svg'
import playStore from '../../images/Play Store.svg'
import apple from '../../images/apple-logo.png'
import android from '../../images/android_logo.png'
import {FaUserLock} from 'react-icons/fa'

const Container = styled.div`
  ${tw`w-full p-3 font-sans h-20 pb-2 `};
`
// const Title = styled.h1`
//   ${tw`text-grey-darkest m-0`};
// `
const StyledLink = styled(Link)`
  ${tw``};
`
const Header = () => {
const [ currPage, setCurrPage] = React.useState('')

 
  React.useEffect(() => {
    setCurrPage(window.location.pathname)
  },[])

  return ( <Container className="homepage-1">

			<div class="flex-1">
      <div class="flex flex-row justify-between"> 
        <div className="">
          <StyledLink to="/">
            <img src={hplogo} alt="healthpeer" className="cursor-pointer h-12 mb-1 fade-in headerlogo"  />
          </StyledLink>
        </div>
        
       <div class="items-center align-middle md:mr-5 self-center">
          {currPage === '/' && <div class="flex flex-row ">
            {/* <div class="flex flex-row text-center  justify-center">
              <p class="text-gray-200 self-center text-center text-lg font-thin m-0 headeravailable">
                      Available on
              </p>
              <img src={apple} class="cursor-pointer h-8 px-5 m-0 hvr-push" />
              <img src={android} class="cursor-pointer h-8 m-0 hvr-push" />
            </div> */}
            <a href="https://testflight.apple.com/join/4r4wzfWx" >
              <img src={appStore} class="h-8 md:h-10 md:mr-5 mb-0  hvr-push"   />
            </a> &nbsp;&nbsp;
            <a href="https://play.google.com/store/apps/details?id=com.healthpeer.hpconsultant">
                <img src={playStore} class="h-8 md:h-10 mb-0 hvr-push" />
            </a> 
            </div>
            
          }
          {currPage === '/medical-professionals' && <div class="flex-row flex">
          <a href="https://testflight.apple.com/join/4r4wzfWx" >
              <img src={appStore} class="h-8 md:h-10 md:mr-5 mb-0  hvr-push"   />
          </a> &nbsp;&nbsp;
          <a href="https://play.google.com/store/apps/details?id=com.healthpeer.hpconsultant">
              <img src={playStore} class="h-8 md:h-10 mb-0 hvr-push" />
          </a> </div>}
          {currPage === '/patients' && <div class="flex flex-row ">
          <a href="#" >
              <img src={appStore} class="h-8 md:h-10 md:mr-5 mb-0  hvr-push"   />
          </a>&nbsp;&nbsp;
          <a href="https://play.google.com/store/apps/details?id=com.healthpeer.hpng">
              <img src={playStore} class="h-8 md:h-10 mb-0  hvr-push" />
          </a> </div>}
         {currPage === '/medical-labs' && <button type="button" class="inline-flex flex-row items-center justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
           <FaUserLock /> &nbsp; Login
          </button>}
       </div>

        
        
      </div>
      </div>
  
  </Container> )
}

export default Header

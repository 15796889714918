import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components';
import ReactModal from 'react-modal'

import './topbar.css'
import hplogo from '../../images/healthpeerHD.png'
import {FaUserLock} from 'react-icons/fa'
import {FiMenu} from 'react-icons/fi'
import {HiOutlineMenuAlt3} from 'react-icons/hi'
import { RiVirusFill } from 'react-icons/ri'
import { BsArrowRightShort } from 'react-icons/bs'
import {IoIosCloseCircleOutline} from 'react-icons/io'


const Container = styled.div`
  ${tw`w-full font-sans h-20 `};
`
// const Title = styled.h1`
//   ${tw`text-grey-darkest m-0`};
// `
const StyledLink = styled(Link)`
  ${tw``};
`

ReactModal.setAppElement('#___gatsby')


function isMobileDevice() {
	if (typeof window !== 'undefined') {
	return ( window.orientation > -1) || (navigator.userAgent.indexOf('IEMobile') !== -1);
	}
};

const customStyles = {
	content : {
	  top                   : isMobileDevice() ? '55%' : '50%',
	  left                  : '50%',
	  right                 : 'auto',
	  bottom                : 'auto',
	  marginRight           : '-50%',
	  transform             : 'translate(-50%, -50%)',
	  height:  500,
	  width: isMobileDevice() ? '90%' : '55%',
	},
	overlay: {zIndex: 50}
  };

const Header = () => {

const [ currPage, setCurrPage] = React.useState('')
const [ show, setShow] = React.useState(false)
const [openModal, setModal] = React.useState(false)

	function handleModal(){
		setModal(!openModal)
	} 


  function setDet(){
    setShow(!show)
  }
  React.useEffect(() => {
    setCurrPage(window.location.pathname)
  },[])

  return ( <> 
  

  <Container className="homepage-tp">
  <ReactModal 
                isOpen={openModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={handleModal}
                style={customStyles}
                > 
			<button class="flex justify-self-end self-end absolute top-0 pt-2 cursor-pointer z-50" onClick={handleModal}> 
			<IoIosCloseCircleOutline class="text-gray-600"/>
			</button>
			<br/>
        <iframe
            allow="microphone;"
            class="w-full h-full p-2"
            src="https://console.dialogflow.com/api-client/demo/embedded/2c4de002-2253-4671-b761-258ed0232aba">
        </iframe>
		</ReactModal>
  <div class="flex flex-row bg-indigo-900 bg-opacity-30 justify-center cursor-pointer p-1" onClick={handleModal}>
      <span class="p-1"><RiVirusFill class="text-green-500" fontSize={18} /></span> &nbsp;
      <span class="flex text-white text-sm font-medium"> Take a quick COVID-19 assessment <BsArrowRightShort fontSize={26} /></span>
      </div>

			<div class="flex-1 p-1">
      <div class="flex flex-row justify-between items-center"> 
        <div >
          <StyledLink to="/">
            <img src={hplogo} alt="healthpeer" className="cursor-pointer ml-3 h-12 mb-1 fade-in md:pl-4"  />
          </StyledLink>
        </div>
        
       <div class="hidden md:flex items-center align-middle flex-row mr-5 justify-around self-center w-5/12">
      <span class="wobble-horizontal">
      <StyledLink to="/">
       <span className="p-1 cursor-pointer flex items-center hover:bg-gray-200 text-md text-gray-800 rounded" style={{backgroundColor: currPage === '/' && '#D1D5DB', color:currPage === '/' && '#3730A3' }}>
            Home
          </span>
      </StyledLink>
      </span>
      <span class="wobble-horizontal">
      <StyledLink to="/faqs">
       <span className="p-1 cursor-pointer flex items-center hover:bg-gray-200 text-md text-gray-800 rounded " style={{backgroundColor: currPage === '/faqs' && '#D1D5DB',color: currPage === '/faqs' && '#3730A3' }}>
            Faqs
          </span>
      </StyledLink>
      </span>
      <span class="wobble-horizontal">
      <StyledLink to="/blog">
       <span className="p-1 cursor-pointer flex items-center hover:bg-gray-200 text-md text-gray-800 rounded" style={{backgroundColor: currPage === '/blog' && '#D1D5DB', color: currPage === '/blog' && '#3730A3' }}>
            Blog
          </span>
      </StyledLink>
      </span>
      <span class="wobble-horizontal">
      <StyledLink to="/about">
       <span className="p-1 cursor-pointer flex items-center hover:bg-gray-200 text-md text-gray-800 rounded" style={{backgroundColor: currPage === '/about' && '#D1D5DB',color: currPage === '/about' && '#3730A3' }}>
            About us
          </span>
      </StyledLink>
      </span>
      {currPage === '/medical-labs' && <button type="button" class="flex flex-row items-center justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
           <FaUserLock /> &nbsp; Login
          </button>}
       </div>

        <HiOutlineMenuAlt3 onClick={setDet} class="flex md:hidden align-middle color-indigo-800" style={{fontSize:30}}/>

      </div>

      {show && <div class="flex bg-transparent float-right">
        <div class=" px-2 pb-3 space-y-1">
        <StyledLink to="/" onClick={setDet}>
          <span className="p-1 cursor-pointer flex items-center hover:bg-gray-300 text-md text-gray-800 font-medium rounded " >
            HOME
          </span>
        </StyledLink>
        <StyledLink to="/faqs" onClick={setDet}>
          <span className="p-1 cursor-pointer flex items-center hover:bg-gray-300 text-md text-gray-800 font-medium rounded " >
            FAQs
          </span>
        </StyledLink>
        <StyledLink to="/blog" onClick={setDet}>
          <span className="p-1 cursor-pointer flex items-center hover:bg-gray-300 text-md text-gray-800 font-medium rounded " >
            BLOG
          </span>
        </StyledLink>
          <StyledLink to="/about" onClick={setDet}>
            <span className="p-1 cursor-pointer flex items-center hover:bg-gray-300 text-md text-gray-800 font-medium rounded " >
              ABOUT US
            </span>
        </StyledLink>

        {currPage === '/medical-labs' && <span className="p-1 cursor-pointer flex items-center hover:bg-gray-300 text-md text-gray-800 font-medium rounded " >
              LOGIN
            </span>}
        </div>
        </div>}
      </div>
  
  </Container> 
  </>)
}

export default Header


import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components';

import facebook from '../../images/facebook.png'
import twitter from '../../images/twitter.png'
import instagram from '../../images/instagram-sketched.png'
import './social.css'

const Container = styled.div`
  ${tw`container bg-indigo-lightest pt-18 md:pt-36 mx-auto flex flex-wrap flex-col md:flex-row items-center`};
`

const Socials = () => (
    <Container>
        <div class="flex flex-wrap w-full bg-transparent " >
                        {/* <div class=" text-center w-1/2">
                            <a class="twitter-timeline" href="https://twitter.com/healthpeerng?ref_src=twsrc%5Etfw" data-tweet-limit="1">Tweets by Healthpeer NG</a>
                        </div> */}
                        {/* <div class="w-1/2 text-center"> */}
                                <div class="pt-24 pb-4 mx-4 justify-between text-center socialhealth">
                                    <h3 class="text-indigo-800 font-thin mx-4">Get updates and connect with us on social media </h3>
                                    <div class="about">
                                        <a class="bg_links social twitter" href="https://www.twitter.com/healthpeerng" target="_blank">
                                            <span class="icon"></span>
                                        </a>
                                        <a class="bg_links social instagram mx-4" href="https://www.instagram.com/healthpeerng" target="_blank">
                                            <span class="icon"></span>
                                        </a>
                                        <a class="bg_links social facebook" href="https://www.facebook.com/healthpeerng" target="_blank">
                                            <span class="icon"></span>
                                        </a>
                                       
                                    </div>
                                    {/* <p class="flex justify-around items-center">
                                        <img src={facebook} alt="healthpeer" className=" h-12 pt-1" />
                                        <img src={twitter} alt="healthpeer" className="cursor-pointer h-12  pt-1" />
                                        <img src={instagram} alt="healthpeer" className="cursor-pointer h-12  pt-1" />
                                    </p> */}
                                </div>
                        {/* </div> */} 
                    </div>
    </Container>
)
export default Socials
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../../images/healthpeer-white.png';
import appStore from '../../images/App Store.svg'
import playStore from '../../images/Play Store.svg'
import Socials from '../Socials/socials'

import './footer.css';

const Container = styled.div`
  ${tw`w-full pt-16 pb-6 text-sm text-center fade-in font-sans px-12`};
`

const FooterContent = styled.div`
  ${tw` flex-row justify-between`}
`

const Title = styled.span`
  ${tw`text-gray-500 no-underline hover:no-underline`};
`
const StyledLink = styled(Link)`
  ${tw`text-gray-500 no-underline hover:no-underline`};
`

const Footer = ({ siteDetails }) => (
  <>
  <Socials />
  <Container className="row bg-indigo-900">
    <FooterContent  className="footerhealth">
      <div style={{ color: 'rgb(216, 216, 216)' }}>
      <StyledLink to="/"><img src={logo} class="footerimagelogo" width="200" alt="" /></StyledLink>
        <p style={{ maxWidth: '20em', fontSize: '10' }} class="text-justify footerparag">
        Speak to a Doctor/Specialist one-on-one at your convenience any time any where. 
        Using our text-chat or voice chat, 
        You can get instant medical Consultation at an affordable cost 24/7.
        </p>
        <p className="footercontact">
          Contact: +234 8104734032
          <br />
          <a href="mailto:hi@healthpeerng.com" >hi@healthpeerng.com</a>
          <br />
          <a href="mailto:healthpeerng@gmail.com">healthpeerng@gmail.com</a>
        </p>
      </div>
      <div style={{ color: 'rgb(216, 216, 216)' }} class="lg:text-left">
        <h4 style={{ color: 'white' }}>Who we are</h4>
        <StyledLink to="/about"><p>About Us</p></StyledLink>
        <StyledLink to="/blog"><p>Blog</p></StyledLink>
        <StyledLink to="/faqs"><p>Faq</p></StyledLink>
        <StyledLink to="/terms-of-service"><p>Terms of Service</p></StyledLink>
        <StyledLink to="/privacy-policy"><p>Privacy Policy</p></StyledLink>
      </div>
      <div style={{ color: 'rgb(216, 216, 216)' }} class="lg:text-left">
        <h4 style={{ color: 'white' }}>Services Link</h4>
        <StyledLink to="/"><p>Symptom Checker</p></StyledLink>
        <StyledLink to="/patients"><p>Patients</p></StyledLink>
        <StyledLink to="/medical-professionals"><p>Medical Professionals</p></StyledLink>
        <StyledLink to="/medical-labs"><p>Medical labs/ Pharmacies</p></StyledLink>
        <StyledLink to="/careers"><p>Careers</p></StyledLink>
      </div>
      {/* <div style={{ color: 'rgb(216, 216, 216)' }}>
        <h4 style={{ color: 'white' }}>Follow Us</h4>
        <div style={{display: "flex", justifyContent: "space-evenly"}} >
          <p>Facebook|</p>
          <p>Twitter|</p>
          <p>LinkedIn|</p>
          <p>YouTube</p>
        </div>
      </div> */}
    </FooterContent>
    <div class="footerend"
      style={{
        justifyContent: 'space-between',
      }}
    >
      <div class="flex flex-row footerappdownload">
        <img src={appStore} class=" mr-4 bounce-top-icons" width="100" />
        {"   "}
        <a href="https://play.google.com/store/apps/details?id=com.healthpeer.hpng">
        <img src={playStore} class=" bounce-top-icons" width="100" /></a>
      </div>
      <Title style={{ color: 'rgb(216, 216, 216)' }}>
      Healthpeer &copy; 2021/22. All rights reserved
      </Title>
    </div>
  </Container>
  </>
)

export default Footer
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import $ from 'jquery'

import Header from './Header/header'
import Topbar from './Topbar/topbar'
import Footer from './Footer/footer'
import './layout.css'
import bgPath from '../images/bg.svg'


const Content = styled.div`
  ${tw`leading-normal tracking-normal font-sans`};
`
const BG = styled.div`
  ${tw`h-screen pb-14 bg-right bg-cover`};
`

const Layout = ({ children }) => {
  const [show, setShow] = React.useState(false)
  const [scrollPos, setScroll] = React.useState(0)

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })

  function callbackFunc(entries, observer)
{
  entries.forEach(entry => {
    var txt = entry.target.id + " visibility: " + entry.isIntersecting;
    
    document.getElementById('___gatsby').appendChild(document.createTextNode(txt));
    document.getElementById('___gatsby').appendChild(document.createElement("br"));
  });
}

let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3
  };

// let observer = new IntersectionObserver(callbackFunc, options);
//   observer.observe($('.slide-in-bottom'));
//   observer.observe($('.slide-in-bottom-h1'));
//   observer.observe($('.slide-in-bottom-subtitle'));
//   observer.observe($('.slide-in-bottom-subtitle1'));
//   observer.observe($('.slide-in-bottom-subtitle2'));

  function isMobileDevice() {
    if (typeof window !== 'undefined') {
    return ( window.orientation > -1) || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }
  };

  const handleScroll = () => {
    setScroll(document.body.getBoundingClientRect().top)
    setShow(document.body.getBoundingClientRect().top < -window.innerHeight ? true : false)
    
  }

  return (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Healthpeer' },
            { name: 'keywords', content: 'health, healthpeer, medical, consultation,free medical consultation,medical chat,Healthpeer,Healthpeer NG,medical app, health app,free,doctor,peer,' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        {/* <script src="https://www.gstatic.com/firebasejs/8.2.1/firebase-app.js"></script>
        <script src="https://www.gstatic.com/firebasejs/8.2.1/firebase-firestore.js"></script> */}
        {show && <Header />}
        {!show && <Topbar />}
        {/* </BG> */}
        
        <Content>{children}</Content>
        
        <Footer />
      </>
    )}
  />
)}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
